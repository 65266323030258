import type { RequestOpts } from "@oazapfts/runtime";

const config = useRuntimeConfig();
export default {
  baseUrl: config.public.API_URL,
} as RequestOpts;

export async function createRequestionOptions(): RequestOpts {
  const { $i18n, $auth } = useNuxtApp();

  const options: RequestOpts = {
    baseUrl: config.public.API_URL,
    headers: new Headers(),
  };

  options.headers.append("Accept", "application/json");
  if ($i18n?.locale) {
    options.headers.append("Accept-Language", $i18n.locale.value);
  }

  const token = await $auth.getToken();
  if (!token) {
    //        globalNotification.showError("Expected a Authorization token, found none.");
  } else {
    const bearer = "Bearer " + token;
    options.headers.append("Authorization", bearer);
  }

  return options;
}
