<template>
  <section class="bg-ngray-6 w-full flex-1 h-[calc(100vh-75px)] overflow-auto px-6 py-4 flex flex-col gap-4">
    <!-- min-h-0 added to make sure contend does not overflows: https://stackoverflow.com/a/33612613 -->
    <div class="relative flex flex-col bg-white rounded-lg flex-1 p-1 layout-wrapper min-h-0">
      <slot />
    </div>
    <version-footer />
  </section>
</template>

<style scoped>
.layout-wrapper {
  container: layout-wrapper/inline-size;
}
</style>
