export function mockProductCategoriesOptions(): ValueLabelPair[] {
  return [
    {
      label: "Bread and Rolls",
      value: "bread-and-rolls",
    },
    {
      label: "Cakes and Pastries",
      value: "cakes-and-pastries",
    },
    {
      label: "Donuts and Muffins",
      value: "donuts-and-muffins",
    },
    {
      label: "Pies and Tarts",
      value: "pies-and-tarts",
    },
  ];
}

export function mockRoomsAndLayoutsOptions(): ValueLabelPair<RoomsCapacity>[] {
  return [
    {
      label: "0-20",
      value: {
        min: 0,
        max: 20,
      },
    },
    {
      label: "21-50",
      value: {
        min: 21,
        max: 50,
      },
    },
    {
      label: "50- 100",
      value: {
        min: 50,
        max: 100,
      },
    },
    {
      label: "101 or more",
      value: {
        min: 101,
        max: 2_147_483_647, //MAX_INT_32
      },
    },
  ];
}

export function mockCorporateCategoriesOptions(): string[] {
  return ["Major asset", "Routine", "Janitorial", "Packaging"];
}
