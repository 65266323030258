import { defineStore } from "pinia";
import { isEqual } from "lodash-es";
import {
  mockProductCategoriesOptions,
  mockRoomsAndLayoutsOptions,
  mockCorporateCategoriesOptions,
} from "./mock-data";
import { type FacilityDto } from "@base/connectables/fetch/setup";
import { type OrganizationDto } from "@base/connectables/fetch/organization";
import {
  fetchCleaningRoleOptions,
  fetchFacility,
  fetchIndustries,
  fetchOrganization,
  syncFacility,
  syncOrganization,
} from "./api";

export const useSetupStore = defineStore("setup", () => {
  const skipIntroduction = useLocalStorage<boolean>(
    "setup.skipIntroduction",
    false,
  );

  const currentFacility = ref<FacilityDto>();
  const currentOrganization = ref<OrganizationDto>();

  async function setSkipIntroduction(value: boolean) {
    skipIntroduction.value = value;
  }

  async function saveOrganization(
    payload:
      | {
          isPartOfOrganization: true;
          name: string;
          industry: string;
        }
      | { isPartOfOrganization: false },
  ) {
    const { isPartOfOrganization, ...update } = payload;
    const newOrganization = isPartOfOrganization
      ? {
          ...currentOrganization.value,
          ...update,
        }
      : undefined;
    currentOrganization.value = await syncOrganization(
      newOrganization,
      currentOrganization.value,
    );
  }

  async function saveFacility(facilityUpdate: Partial<FacilityDto>) {
    const organizationId = currentOrganization.value?.id;
    const newFacility = {
      ...currentFacility.value,
      ...facilityUpdate,
      organizationId,
    };

    if (isEqual(newFacility, currentFacility.value)) {
      return;
    }
    const updatedFacility = await syncFacility(
      newFacility,
      currentFacility.value,
    );
    currentFacility.value = updatedFacility;
  }

  async function loadFacility(id: number) {
    currentFacility.value = await fetchFacility(id);
    if (currentFacility.value.organizationId) {
      currentOrganization.value = await fetchOrganization(
        currentFacility.value.organizationId,
      );
    } else {
      currentOrganization.value = undefined;
    }
  }

  async function resetFacility() {
    currentFacility.value = undefined;
    currentOrganization.value = undefined;
  }

  return {
    currentFacility,
    currentOrganization,
    skipIntroduction,
    setSkipIntroduction,
    getIndustryItems: fetchIndustries,
    getProductCategories: mockProductCategoriesOptions,
    getRoomsAndLayouts: mockRoomsAndLayoutsOptions,
    getCorporateCategories: mockCorporateCategoriesOptions,
    getCleaningRoleOptions: fetchCleaningRoleOptions,
    saveOrganization,
    saveFacility,
    loadFacility,
    resetFacility,
  };
});
