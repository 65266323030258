<script setup lang="ts"></script>

<template>
  <div class="p-3 w-full h-14 bg-white border-b border-slate-300 flex items-center justify-between">
    <slot />
    <div class="flex gap-1">
      <select-locale />
      <button-user-avatar />
    </div>
  </div>
</template>
