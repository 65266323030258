<script setup lang="ts">
import { useAppStore } from "../stores/app";

const store = useAppStore();
</script>
<template>
  <div v-cloak>
    <div
      v-if="store?.loading"
      class="absolute z-50 bg-ngray-6/25 backdrop-blur-sm w-full h-full flex justify-center items-center"
    >
      <img src="assets/svg/logo-pinwheel.svg" class="w-32 h-10 animate-spin" :alt="$t('alt.spinner')" />
    </div>
    <slot />
  </div>
</template>
