import { type CleaningRoleCategory } from "~/schemas/setupDto";
import { getCleaningRoles } from "@base/connectables/fetch/cleaning";
import {
  getFacility,
  createFacility,
  updateFacility,
  type CleaningRoleDto,
  type FacilityDto,
} from "@base/connectables/fetch/setup";
import {
  createOrganization,
  updateOrganization,
  deleteOrganization,
  getOrganization,
  type OrganizationDto,
} from "@base/connectables/fetch/organization";
import { isEqual } from "lodash-es";
import { getIndustryLookupList } from "@base/connectables/fetch/industry";

async function createSetupRequestOptions(config?: { facilityId?: number }) {
  const options = await createRequestionOptions();
  if (config?.facilityId) {
    options.headers.append("Nexcor-Plant", config.facilityId.toString());
  }
  return options;
}

export function facilityDtoDefaults(): Partial<FacilityDto> {
  return {
    organizationId: null,
    minimumBuildings: 0,
    maximumBuildings: 0,
    minimumRoomsAreas: 0,
    maximumRoomsAreas: 0,
    productCategories: [],
    skills: [],
    corporateCategories: [],
  };
}

export async function fetchIndustries(): Promise<string[]> {
  const options = await createSetupRequestOptions();
  const response = await getIndustryLookupList(options);
  if (response.status !== 200) {
    throw new Error("cksetup.wizard.failedToFetchIndustries");
  }
  return response.data.map(({ name }) => name);
}

export async function fetchCleaningRoleOptions(
  category: CleaningRoleCategory,
): Promise<ValueLabelPair<CleaningRoleDto>[]> {
  const options = await createSetupRequestOptions();
  const roles = await getCleaningRoles(category, options);
  if (roles.status !== 200) {
    throw new Error("cksetup.wizard.failedToFetchCleaningRoles");
  }
  return roles.data.map((role) => ({
    value: role,
    label: role.name,
  }));
}

export async function fetchFacility(facilityId: number): Promise<FacilityDto> {
  const options = await createSetupRequestOptions({ facilityId });
  const response = await getFacility(facilityId, options);
  if (response.status !== 200) {
    throw new Error("cksetup.wizard.failedToFetchFacility");
  }
  // TODO (JM): this will change on the API
  return response.data as unknown as FacilityDto;
}

export async function fetchOrganization(id: number): Promise<OrganizationDto> {
  const options = await createSetupRequestOptions();
  const response = await getOrganization(id, options);
  if (response.status !== 200) {
    throw new Error("cksetup.wizard.failedToFetchOrganization");
  }
  return response.data;
}

export async function syncOrganization(
  newOrganizationDto?: Partial<OrganizationDto>,
  currentOrganizationDto?: OrganizationDto,
): Promise<OrganizationDto | undefined> {
  const options = await createSetupRequestOptions();

  if (
    (!newOrganizationDto && !currentOrganizationDto) ||
    isEqual(newOrganizationDto, currentOrganizationDto)
  ) {
    return currentOrganizationDto;
  }
  if (!newOrganizationDto && currentOrganizationDto?.id) {
    const response = await deleteOrganization(
      currentOrganizationDto?.id,
      options,
    );
    if (response.status !== 200) {
      throw new Error("cksetup.wizard.failedToDeleteOrganization");
    }
    return undefined;
  }
  if (newOrganizationDto && !currentOrganizationDto?.id) {
    const response = await createOrganization(
      {
        name: newOrganizationDto.name!,
        industry: newOrganizationDto.industry,
      } as OrganizationDto,
      options,
    );

    if (response.status !== 200) {
      throw new Error("cksetup.wizard.failedToCreateOrganization");
    }
    return response.data;
  }

  const response = await updateOrganization(
    currentOrganizationDto!.id,
    {
      id: currentOrganizationDto!.id,
      name: newOrganizationDto!.name!,
      industry: newOrganizationDto!.industry,
    } as OrganizationDto,
    options,
  );

  if (response.status !== 200) {
    throw new Error("cksetup.wizard.failedToUpdateOrganization");
  }

  return response.data;
}

export async function syncFacility(
  newFacilityDto: Partial<FacilityDto>,
  currentFacilityDto?: FacilityDto,
): Promise<FacilityDto> {
  const options = await createSetupRequestOptions({
    facilityId: currentFacilityDto?.id,
  });
  if (!currentFacilityDto?.id) {
    const defaults = facilityDtoDefaults();
    const response = await createFacility(
      {
        ...defaults,
        ...newFacilityDto,
      } as FacilityDto,
      options,
    );
    if (response.status !== 200 || !response.data) {
      throw new Error("cksetup.wizard.failedToCreateFacility");
    }
    // TODO (JM): this will change on the API
    const responseDto = response.data as unknown as FacilityDto;
    return responseDto;
  }
  const response = await updateFacility(
    currentFacilityDto.id,
    newFacilityDto as FacilityDto,
    options,
  );
  if (response.status !== 200) {
    throw new Error("cksetup.wizard.failedToUpdateFacility");
  }
  // TODO (JM): this will change on the API
  const responseDto = response.data as unknown as FacilityDto;
  return responseDto;
}
