import facilitySteps from "@/pages/setup/setupSteps";

export default defineNuxtRouteMiddleware((to) => {
  const setupStore = useSetupStore();
  const localePath = useLocalePath();

  const path = to.path.replace(/\/$/, "");
  const skipIntroduction =
    setupStore.skipIntroduction || !!setupStore.currentFacility?.id;
  if (path.endsWith("/setup")) {
    if (skipIntroduction) {
      return navigateTo(localePath(facilitySteps[1].url));
    } else {
      return navigateTo(localePath(facilitySteps[0].url));
    }
  }
  if (path.endsWith(facilitySteps[0].url) && skipIntroduction) {
    return navigateTo(localePath(facilitySteps[1].url));
  }
  return true;
});
