import { type ComposerTranslation, type ComposerDateTimeFormatting } from "vue-i18n";
import dayjs from "dayjs";
import { isNull } from "assertate";

// date
export const getLocalizedWeekdayArray = (t: ComposerTranslation) => [
  t("date.sunday"),
  t("date.monday"),
  t("date.tuesday"),
  t("date.wednesday"),
  t("date.thursday"),
  t("date.friday"),
  t("date.saturday"),
];

export function isInvalidDateOrString(source: string | Date | null) {
  return (
    !source ||
    (typeof source === "string" && source?.length < 3) ||
    (source instanceof Date && isNaN((<Date>source).valueOf()))
  );
}

// set time to middle of the day to avoid time zone/daylight savings shenanigans
export function addTimeToDateString(date: string | Date) {
  if (!date || isInvalidDateOrString(date)) return null;
  let dateString;
  if (date instanceof Date) {
    dateString = date.toISOString().split("T")?.[0];
  }
  if (typeof date === "string" && date.includes("T")) {
    dateString = date?.split("T")?.[0];
  }
  if (typeof date === "string") {
    // handle 2/5/2024 format from workpack options without regex
    dateString = new Date(date).toISOString().split("T")?.[0];
  }
  dateString += "T12:30:30.0000Z";
  return dateString;
}

export const formatDateString = (source: string | Date | null | undefined, d: ComposerDateTimeFormatting) => {
  if (!source || isInvalidDateOrString(source)) {
    return "--";
  }
  const dateString = addTimeToDateString(source);
  if (!dateString) return "--";
  const date = new Date(dateString);
  return d(date, "short");
};

export const formatTimeString = (source: string | Date | null, d: ComposerDateTimeFormatting) => {
  if (!source || isInvalidDateOrString(source)) {
    return "--";
  }
  const dateString = addTimeToDateString(source);
  if (!dateString) return "--";
  const date = new Date(dateString);
  return d(date, "timeShort");
};

export const isValidDateString = (timestamp: string) => {
  return dayjs(timestamp).isValid();
};

export const getDateStringDay = (timestamp: string) => {
  if (isInvalidDateOrString(timestamp) || timestamp?.length < 3) {
    return "";
  }
  const dateString = addTimeToDateString(timestamp);
  if (dateString === null || dateString === undefined) return "";
  const date = new Date(dateString!);
  return date.getDay();
};

function padNumber(num: number) {
  if (num < 10) return num.toString().padStart(2, "0");
  return num;
}

export const getDateString = (source: string | Date | null) => {
  if (!source || isInvalidDateOrString(source)) {
    return null;
  }
  const dateString = addTimeToDateString(source);
  if (!dateString) return null;
  const date = new Date(dateString);
  const month = padNumber(date.getMonth() + 1);
  const day = padNumber(date.getDate());
  return `${date.getFullYear()}-${month}-${day}`;
};

export function formatDateLabel(timestamp: string, locale: string) {
  if (!timestamp || isInvalidDateOrString(timestamp)) return null;
  const dateTimeString = addTimeToDateString(timestamp);
  if (!dateTimeString) return null;
  const dateOptions = {
    year: "numeric" as const,
    month: "2-digit" as const,
    day: "2-digit" as const,
  };
  const date = new Date(dateTimeString);
  const dateString = new Intl.DateTimeFormat(locale, dateOptions).format(date);
  const shortWeekday = new Intl.DateTimeFormat(locale, { weekday: "short" }).format(date);

  return `${shortWeekday}, ${dateString}`;
}

export function formatDateLabelName(timestamp: string, locale: string) {
  if (!timestamp || isInvalidDateOrString(timestamp)) return null;
  const dateTimeString = addTimeToDateString(timestamp);
  if (!dateTimeString) return null;
  const date = new Date(dateTimeString);
  const shortWeekday = new Intl.DateTimeFormat(locale, { weekday: "short" }).format(date);

  return `${shortWeekday}`;
}

export function formatWorkpackDayAndDateString(day: number, date: string, locale: string) {
  if (!day || !date || !locale) return "";
  if (day > 7 || day < 1) return "";
  return `D${day}, ${formatDateLabel(date, locale)}`;
}

export function formatTotalMinutes(totalMins: number, t: ComposerTranslation) {
  const hours = Math.floor(totalMins / 60);
  const minutes = totalMins % 60;
  const hrs = t("formFields.hrs");
  const mins = t("formFields.mins");
  return hours > 0 ? `${hours}${hrs} ${minutes}${mins}` : `${minutes}${mins}`;
}

export function getDayDifference(start: Date, end: Date) {
  if (end < start) throw Error("End Date must be after Start Date");

  return Math.round((Number(end) - Number(start)) / TimeInMS.ONE_DAY);
}

export function addDays(start: Date | string, value: number) {
  const date = new Date(start);
  date.setDate(date.getDate() + value);
  return date;
}

export function getTimeFromString(dateTimeString: string): number | null {
  if (!dateTimeString || isInvalidDateOrString(dateTimeString)) return null;
  return new Date(dateTimeString).getTime();
}

function validateDateOnlyStrings(string: string) {
  if (!string || isInvalidDateOrString(string) || string.length !== 10) return false;
  return true;
}

export function checkTrainingDates(begin: string, end: string) {
  if (!validateDateOnlyStrings(begin) || !validateDateOnlyStrings(end)) return false;
  /*
   * .getTime() allows us to use comparison operators on the dates
   */
  const trainEnd = getTimeFromString(end);
  const trainBegin = getTimeFromString(begin);
  /*
   * Training end date must be the same as or after training start date
   */
  return !isNull(trainEnd) && !isNull(trainBegin) && trainBegin <= trainEnd;
}

type CertDateCheckResult = {
  certStart: boolean;
  certEnd: boolean;
  certLengthDate: string | null;
};

export function checkCertificationDates(
  trainEnd: string,
  certStart: string,
  certEnd: string | null,
  certLength: number | null,
): CertDateCheckResult {
  const result: CertDateCheckResult = {
    certStart: false,
    certEnd: false,
    certLengthDate: null,
  };
  if (
    !validateDateOnlyStrings(trainEnd) ||
    !validateDateOnlyStrings(certStart) ||
    !certEnd ||
    !validateDateOnlyStrings(certEnd)
  )
    return result;
  if (typeof certLength !== "number") return result;
  /*
   * .getTime() allows us to use comparison operators on the dates
   */
  const trainEndTime = getTimeFromString(trainEnd);
  const certStartTime = getTimeFromString(certStart);
  const certEndTime = getTimeFromString(certEnd);
  /*
   * Add cert length days to cert start to get minimum cert end
   */
  const certLengthDate = certStart && certLength ? getDateString(addDays(certStart, certLength)) : null;
  let certLengthTime = 0;
  if (certLengthDate) {
    result.certLengthDate = certLengthDate;
    const time = getTimeFromString(certLengthDate);
    if (time) certLengthTime = time;
  }
  /*
   * Cert start date must be the same as or after training end date
   */
  result.certStart = !isNull(trainEndTime) && !isNull(certStartTime) && certStartTime >= trainEndTime;
  /*
   * Cert end date must be the same as or after the cert start date + certification length
   */
  result.certEnd = !isNull(certEndTime) && !isNull(certLengthTime) && certEndTime >= certLengthTime;

  return result;
}

export function checkBulkTrainCertStartDate(trainEnd: string, certStart: string) {
  if (!validateDateOnlyStrings(trainEnd) || !validateDateOnlyStrings(certStart)) return false;
  /*
   * .getTime() allows us to use comparison operators on the dates
   */
  const trainEndTime = getTimeFromString(trainEnd);
  const certStartTime = getTimeFromString(certStart);
  /*
   * Cert start date must be the same as or after training end date
   */
  return !isNull(trainEndTime) && !isNull(certStartTime) && certStartTime >= trainEndTime;
}

export const TimeInMS = {
  ONE_SECOND: 1000,
  FIVE_SECONDS: 5 * 1000,
  TEN_SECONDS: 10 * 1000,
  THIRTY_SECONDS: 30 * 1000,
  ONE_MINUTE: 60 * 1000,
  FIVE_MINUTES: 5 * 60 * 1000,
  ONE_HOUR: 1000 * 60 * 60,
  ONE_DAY: 1000 * 60 * 60 * 24,
};
