<script setup lang="ts">
import { useMouseCoords } from "../composables/directives";
const { updateCoords } = useMouseCoords();
</script>

<template>
  <main v-mouse-coords="updateCoords">
    <wait-overlay>
      <import-header>
        <slot name="header" />
      </import-header>
      <content-section class="flex">
        <slot />
      </content-section>
    </wait-overlay>
  </main>
</template>
