<script lang="ts" setup>
const config = useRuntimeConfig();
const versionString = computed(() => {
  return `${config.public.CKSETUP_VERSION}@${config.public.BUILD_TIMESTAMP}`;
});
</script>

<template>
  <div class="flex text-xs text-ngray-5 justify-end -my-3">{{ versionString }}</div>
</template>
