import { default as bulk_45import_45mock4ASQNIxwB4Meta } from "/src/ckSetup/pages/import/bulk-import-mock.ts?macro=true";
import { default as bulk_45importob16Us78h9Meta } from "/src/ckSetup/pages/import/bulk-import.vue?macro=true";
import { default as daily_45tasksMCEZi6fU8aMeta } from "/src/ckSetup/pages/import/daily-tasks.vue?macro=true";
import { default as equipment_45areasfmTcawiZH3Meta } from "/src/ckSetup/pages/import/equipment-areas.vue?macro=true";
import { default as global_45taskstliQgn9vmMMeta } from "/src/ckSetup/pages/import/global-tasks.vue?macro=true";
import { default as indexUUYgSDG81jMeta } from "/src/ckSetup/pages/import/index.vue?macro=true";
import { default as manufacturersz3ExRyM3cEMeta } from "/src/ckSetup/pages/import/manufacturers.vue?macro=true";
import { default as production_45linesakW2BLWufgMeta } from "/src/ckSetup/pages/import/production-lines.vue?macro=true";
import { default as single_45importke0TRPTSEyMeta } from "/src/ckSetup/pages/import/single-import.vue?macro=true";
import { default as task_45list9MOx5JzbkDMeta } from "/src/ckSetup/pages/import/task-list.vue?macro=true";
import { default as work_45groupspP953MklEGMeta } from "/src/ckSetup/pages/import/work-groups.vue?macro=true";
import { default as indexCeXGZl0jijMeta } from "/src/ckSetup/pages/index.vue?macro=true";
import { default as loginWFniq90GjDMeta } from "/src/ckBase/pages/login.vue?macro=true";
import { default as allergenspJ6Eaf0kyfMeta } from "/src/ckSetup/pages/setup/allergens.vue?macro=true";
import { default as categoriesPkloYDHVAwMeta } from "/src/ckSetup/pages/setup/categories.vue?macro=true";
import { default as cleaning_45equipment8uC89mF8hwMeta } from "/src/ckSetup/pages/setup/cleaning-equipment.vue?macro=true";
import { default as cleaning_45infrastructurepRXn4eFgEWMeta } from "/src/ckSetup/pages/setup/cleaning-infrastructure.vue?macro=true";
import { default as cleaning_45otherNBUd0BNUyqMeta } from "/src/ckSetup/pages/setup/cleaning-other.vue?macro=true";
import { default as doneuznbBMWl12Meta } from "/src/ckSetup/pages/setup/done.vue?macro=true";
import { default as infog7R7HAghwpMeta } from "/src/ckSetup/pages/setup/info.vue?macro=true";
import { default as intro531BtyXOF7Meta } from "/src/ckSetup/pages/setup/intro.vue?macro=true";
import { default as product_45categoriesHWdxSeq1muMeta } from "/src/ckSetup/pages/setup/product-categories.vue?macro=true";
import { default as roomsVTZrkejpjPMeta } from "/src/ckSetup/pages/setup/rooms.vue?macro=true";
import { default as setupSteps9pJs4wetPrMeta } from "/src/ckSetup/pages/setup/setupSteps.ts?macro=true";
import { default as skills0lvLHnrk72Meta } from "/src/ckSetup/pages/setup/skills.vue?macro=true";
import { default as setuphY0ccoWTcmMeta } from "/src/ckSetup/pages/setup.vue?macro=true";
export default [
  {
    name: "import-bulk-import-mock___en",
    path: "/import/bulk-import-mock",
    component: () => import("/src/ckSetup/pages/import/bulk-import-mock.ts")
  },
  {
    name: "import-bulk-import-mock___es",
    path: "/es/import/bulk-import-mock",
    component: () => import("/src/ckSetup/pages/import/bulk-import-mock.ts")
  },
  {
    name: "import-bulk-import-mock___ar",
    path: "/ar/import/bulk-import-mock",
    component: () => import("/src/ckSetup/pages/import/bulk-import-mock.ts")
  },
  {
    name: "import-bulk-import-mock___ja",
    path: "/ja/import/bulk-import-mock",
    component: () => import("/src/ckSetup/pages/import/bulk-import-mock.ts")
  },
  {
    name: "import-bulk-import-mock___fr",
    path: "/fr/import/bulk-import-mock",
    component: () => import("/src/ckSetup/pages/import/bulk-import-mock.ts")
  },
  {
    name: "import-bulk-import-mock___hi",
    path: "/hi/import/bulk-import-mock",
    component: () => import("/src/ckSetup/pages/import/bulk-import-mock.ts")
  },
  {
    name: "import-bulk-import-mock___pt",
    path: "/pt/import/bulk-import-mock",
    component: () => import("/src/ckSetup/pages/import/bulk-import-mock.ts")
  },
  {
    name: "import-bulk-import___en",
    path: "/import/bulk-import",
    component: () => import("/src/ckSetup/pages/import/bulk-import.vue")
  },
  {
    name: "import-bulk-import___es",
    path: "/es/import/bulk-import",
    component: () => import("/src/ckSetup/pages/import/bulk-import.vue")
  },
  {
    name: "import-bulk-import___ar",
    path: "/ar/import/bulk-import",
    component: () => import("/src/ckSetup/pages/import/bulk-import.vue")
  },
  {
    name: "import-bulk-import___ja",
    path: "/ja/import/bulk-import",
    component: () => import("/src/ckSetup/pages/import/bulk-import.vue")
  },
  {
    name: "import-bulk-import___fr",
    path: "/fr/import/bulk-import",
    component: () => import("/src/ckSetup/pages/import/bulk-import.vue")
  },
  {
    name: "import-bulk-import___hi",
    path: "/hi/import/bulk-import",
    component: () => import("/src/ckSetup/pages/import/bulk-import.vue")
  },
  {
    name: "import-bulk-import___pt",
    path: "/pt/import/bulk-import",
    component: () => import("/src/ckSetup/pages/import/bulk-import.vue")
  },
  {
    name: "import-daily-tasks___en",
    path: "/import/daily-tasks",
    component: () => import("/src/ckSetup/pages/import/daily-tasks.vue")
  },
  {
    name: "import-daily-tasks___es",
    path: "/es/import/daily-tasks",
    component: () => import("/src/ckSetup/pages/import/daily-tasks.vue")
  },
  {
    name: "import-daily-tasks___ar",
    path: "/ar/import/daily-tasks",
    component: () => import("/src/ckSetup/pages/import/daily-tasks.vue")
  },
  {
    name: "import-daily-tasks___ja",
    path: "/ja/import/daily-tasks",
    component: () => import("/src/ckSetup/pages/import/daily-tasks.vue")
  },
  {
    name: "import-daily-tasks___fr",
    path: "/fr/import/daily-tasks",
    component: () => import("/src/ckSetup/pages/import/daily-tasks.vue")
  },
  {
    name: "import-daily-tasks___hi",
    path: "/hi/import/daily-tasks",
    component: () => import("/src/ckSetup/pages/import/daily-tasks.vue")
  },
  {
    name: "import-daily-tasks___pt",
    path: "/pt/import/daily-tasks",
    component: () => import("/src/ckSetup/pages/import/daily-tasks.vue")
  },
  {
    name: "import-equipment-areas___en",
    path: "/import/equipment-areas",
    component: () => import("/src/ckSetup/pages/import/equipment-areas.vue")
  },
  {
    name: "import-equipment-areas___es",
    path: "/es/import/equipment-areas",
    component: () => import("/src/ckSetup/pages/import/equipment-areas.vue")
  },
  {
    name: "import-equipment-areas___ar",
    path: "/ar/import/equipment-areas",
    component: () => import("/src/ckSetup/pages/import/equipment-areas.vue")
  },
  {
    name: "import-equipment-areas___ja",
    path: "/ja/import/equipment-areas",
    component: () => import("/src/ckSetup/pages/import/equipment-areas.vue")
  },
  {
    name: "import-equipment-areas___fr",
    path: "/fr/import/equipment-areas",
    component: () => import("/src/ckSetup/pages/import/equipment-areas.vue")
  },
  {
    name: "import-equipment-areas___hi",
    path: "/hi/import/equipment-areas",
    component: () => import("/src/ckSetup/pages/import/equipment-areas.vue")
  },
  {
    name: "import-equipment-areas___pt",
    path: "/pt/import/equipment-areas",
    component: () => import("/src/ckSetup/pages/import/equipment-areas.vue")
  },
  {
    name: "import-global-tasks___en",
    path: "/import/global-tasks",
    component: () => import("/src/ckSetup/pages/import/global-tasks.vue")
  },
  {
    name: "import-global-tasks___es",
    path: "/es/import/global-tasks",
    component: () => import("/src/ckSetup/pages/import/global-tasks.vue")
  },
  {
    name: "import-global-tasks___ar",
    path: "/ar/import/global-tasks",
    component: () => import("/src/ckSetup/pages/import/global-tasks.vue")
  },
  {
    name: "import-global-tasks___ja",
    path: "/ja/import/global-tasks",
    component: () => import("/src/ckSetup/pages/import/global-tasks.vue")
  },
  {
    name: "import-global-tasks___fr",
    path: "/fr/import/global-tasks",
    component: () => import("/src/ckSetup/pages/import/global-tasks.vue")
  },
  {
    name: "import-global-tasks___hi",
    path: "/hi/import/global-tasks",
    component: () => import("/src/ckSetup/pages/import/global-tasks.vue")
  },
  {
    name: "import-global-tasks___pt",
    path: "/pt/import/global-tasks",
    component: () => import("/src/ckSetup/pages/import/global-tasks.vue")
  },
  {
    name: "import___en",
    path: "/import",
    meta: indexUUYgSDG81jMeta || {},
    component: () => import("/src/ckSetup/pages/import/index.vue")
  },
  {
    name: "import___es",
    path: "/es/import",
    meta: indexUUYgSDG81jMeta || {},
    component: () => import("/src/ckSetup/pages/import/index.vue")
  },
  {
    name: "import___ar",
    path: "/ar/import",
    meta: indexUUYgSDG81jMeta || {},
    component: () => import("/src/ckSetup/pages/import/index.vue")
  },
  {
    name: "import___ja",
    path: "/ja/import",
    meta: indexUUYgSDG81jMeta || {},
    component: () => import("/src/ckSetup/pages/import/index.vue")
  },
  {
    name: "import___fr",
    path: "/fr/import",
    meta: indexUUYgSDG81jMeta || {},
    component: () => import("/src/ckSetup/pages/import/index.vue")
  },
  {
    name: "import___hi",
    path: "/hi/import",
    meta: indexUUYgSDG81jMeta || {},
    component: () => import("/src/ckSetup/pages/import/index.vue")
  },
  {
    name: "import___pt",
    path: "/pt/import",
    meta: indexUUYgSDG81jMeta || {},
    component: () => import("/src/ckSetup/pages/import/index.vue")
  },
  {
    name: "import-manufacturers___en",
    path: "/import/manufacturers",
    component: () => import("/src/ckSetup/pages/import/manufacturers.vue")
  },
  {
    name: "import-manufacturers___es",
    path: "/es/import/manufacturers",
    component: () => import("/src/ckSetup/pages/import/manufacturers.vue")
  },
  {
    name: "import-manufacturers___ar",
    path: "/ar/import/manufacturers",
    component: () => import("/src/ckSetup/pages/import/manufacturers.vue")
  },
  {
    name: "import-manufacturers___ja",
    path: "/ja/import/manufacturers",
    component: () => import("/src/ckSetup/pages/import/manufacturers.vue")
  },
  {
    name: "import-manufacturers___fr",
    path: "/fr/import/manufacturers",
    component: () => import("/src/ckSetup/pages/import/manufacturers.vue")
  },
  {
    name: "import-manufacturers___hi",
    path: "/hi/import/manufacturers",
    component: () => import("/src/ckSetup/pages/import/manufacturers.vue")
  },
  {
    name: "import-manufacturers___pt",
    path: "/pt/import/manufacturers",
    component: () => import("/src/ckSetup/pages/import/manufacturers.vue")
  },
  {
    name: "import-production-lines___en",
    path: "/import/production-lines",
    meta: production_45linesakW2BLWufgMeta || {},
    component: () => import("/src/ckSetup/pages/import/production-lines.vue")
  },
  {
    name: "import-production-lines___es",
    path: "/es/import/production-lines",
    meta: production_45linesakW2BLWufgMeta || {},
    component: () => import("/src/ckSetup/pages/import/production-lines.vue")
  },
  {
    name: "import-production-lines___ar",
    path: "/ar/import/production-lines",
    meta: production_45linesakW2BLWufgMeta || {},
    component: () => import("/src/ckSetup/pages/import/production-lines.vue")
  },
  {
    name: "import-production-lines___ja",
    path: "/ja/import/production-lines",
    meta: production_45linesakW2BLWufgMeta || {},
    component: () => import("/src/ckSetup/pages/import/production-lines.vue")
  },
  {
    name: "import-production-lines___fr",
    path: "/fr/import/production-lines",
    meta: production_45linesakW2BLWufgMeta || {},
    component: () => import("/src/ckSetup/pages/import/production-lines.vue")
  },
  {
    name: "import-production-lines___hi",
    path: "/hi/import/production-lines",
    meta: production_45linesakW2BLWufgMeta || {},
    component: () => import("/src/ckSetup/pages/import/production-lines.vue")
  },
  {
    name: "import-production-lines___pt",
    path: "/pt/import/production-lines",
    meta: production_45linesakW2BLWufgMeta || {},
    component: () => import("/src/ckSetup/pages/import/production-lines.vue")
  },
  {
    name: "import-single-import___en",
    path: "/import/single-import",
    component: () => import("/src/ckSetup/pages/import/single-import.vue")
  },
  {
    name: "import-single-import___es",
    path: "/es/import/single-import",
    component: () => import("/src/ckSetup/pages/import/single-import.vue")
  },
  {
    name: "import-single-import___ar",
    path: "/ar/import/single-import",
    component: () => import("/src/ckSetup/pages/import/single-import.vue")
  },
  {
    name: "import-single-import___ja",
    path: "/ja/import/single-import",
    component: () => import("/src/ckSetup/pages/import/single-import.vue")
  },
  {
    name: "import-single-import___fr",
    path: "/fr/import/single-import",
    component: () => import("/src/ckSetup/pages/import/single-import.vue")
  },
  {
    name: "import-single-import___hi",
    path: "/hi/import/single-import",
    component: () => import("/src/ckSetup/pages/import/single-import.vue")
  },
  {
    name: "import-single-import___pt",
    path: "/pt/import/single-import",
    component: () => import("/src/ckSetup/pages/import/single-import.vue")
  },
  {
    name: "import-task-list___en",
    path: "/import/task-list",
    component: () => import("/src/ckSetup/pages/import/task-list.vue")
  },
  {
    name: "import-task-list___es",
    path: "/es/import/task-list",
    component: () => import("/src/ckSetup/pages/import/task-list.vue")
  },
  {
    name: "import-task-list___ar",
    path: "/ar/import/task-list",
    component: () => import("/src/ckSetup/pages/import/task-list.vue")
  },
  {
    name: "import-task-list___ja",
    path: "/ja/import/task-list",
    component: () => import("/src/ckSetup/pages/import/task-list.vue")
  },
  {
    name: "import-task-list___fr",
    path: "/fr/import/task-list",
    component: () => import("/src/ckSetup/pages/import/task-list.vue")
  },
  {
    name: "import-task-list___hi",
    path: "/hi/import/task-list",
    component: () => import("/src/ckSetup/pages/import/task-list.vue")
  },
  {
    name: "import-task-list___pt",
    path: "/pt/import/task-list",
    component: () => import("/src/ckSetup/pages/import/task-list.vue")
  },
  {
    name: "import-work-groups___en",
    path: "/import/work-groups",
    component: () => import("/src/ckSetup/pages/import/work-groups.vue")
  },
  {
    name: "import-work-groups___es",
    path: "/es/import/work-groups",
    component: () => import("/src/ckSetup/pages/import/work-groups.vue")
  },
  {
    name: "import-work-groups___ar",
    path: "/ar/import/work-groups",
    component: () => import("/src/ckSetup/pages/import/work-groups.vue")
  },
  {
    name: "import-work-groups___ja",
    path: "/ja/import/work-groups",
    component: () => import("/src/ckSetup/pages/import/work-groups.vue")
  },
  {
    name: "import-work-groups___fr",
    path: "/fr/import/work-groups",
    component: () => import("/src/ckSetup/pages/import/work-groups.vue")
  },
  {
    name: "import-work-groups___hi",
    path: "/hi/import/work-groups",
    component: () => import("/src/ckSetup/pages/import/work-groups.vue")
  },
  {
    name: "import-work-groups___pt",
    path: "/pt/import/work-groups",
    component: () => import("/src/ckSetup/pages/import/work-groups.vue")
  },
  {
    name: "index___en",
    path: "/",
    meta: indexCeXGZl0jijMeta || {},
    component: () => import("/src/ckSetup/pages/index.vue")
  },
  {
    name: "index___es",
    path: "/es",
    meta: indexCeXGZl0jijMeta || {},
    component: () => import("/src/ckSetup/pages/index.vue")
  },
  {
    name: "index___ar",
    path: "/ar",
    meta: indexCeXGZl0jijMeta || {},
    component: () => import("/src/ckSetup/pages/index.vue")
  },
  {
    name: "index___ja",
    path: "/ja",
    meta: indexCeXGZl0jijMeta || {},
    component: () => import("/src/ckSetup/pages/index.vue")
  },
  {
    name: "index___fr",
    path: "/fr",
    meta: indexCeXGZl0jijMeta || {},
    component: () => import("/src/ckSetup/pages/index.vue")
  },
  {
    name: "index___hi",
    path: "/hi",
    meta: indexCeXGZl0jijMeta || {},
    component: () => import("/src/ckSetup/pages/index.vue")
  },
  {
    name: "index___pt",
    path: "/pt",
    meta: indexCeXGZl0jijMeta || {},
    component: () => import("/src/ckSetup/pages/index.vue")
  },
  {
    name: "login___en",
    path: "/login",
    meta: loginWFniq90GjDMeta || {},
    component: () => import("/src/ckBase/pages/login.vue")
  },
  {
    name: "login___es",
    path: "/es/login",
    meta: loginWFniq90GjDMeta || {},
    component: () => import("/src/ckBase/pages/login.vue")
  },
  {
    name: "login___ar",
    path: "/ar/login",
    meta: loginWFniq90GjDMeta || {},
    component: () => import("/src/ckBase/pages/login.vue")
  },
  {
    name: "login___ja",
    path: "/ja/login",
    meta: loginWFniq90GjDMeta || {},
    component: () => import("/src/ckBase/pages/login.vue")
  },
  {
    name: "login___fr",
    path: "/fr/login",
    meta: loginWFniq90GjDMeta || {},
    component: () => import("/src/ckBase/pages/login.vue")
  },
  {
    name: "login___hi",
    path: "/hi/login",
    meta: loginWFniq90GjDMeta || {},
    component: () => import("/src/ckBase/pages/login.vue")
  },
  {
    name: "login___pt",
    path: "/pt/login",
    meta: loginWFniq90GjDMeta || {},
    component: () => import("/src/ckBase/pages/login.vue")
  },
  {
    name: "setup___en",
    path: "/setup",
    meta: setuphY0ccoWTcmMeta || {},
    component: () => import("/src/ckSetup/pages/setup.vue"),
    children: [
  {
    name: "setup-allergens___en",
    path: "allergens",
    component: () => import("/src/ckSetup/pages/setup/allergens.vue")
  },
  {
    name: "setup-categories___en",
    path: "categories",
    component: () => import("/src/ckSetup/pages/setup/categories.vue")
  },
  {
    name: "setup-cleaning-equipment___en",
    path: "cleaning-equipment",
    component: () => import("/src/ckSetup/pages/setup/cleaning-equipment.vue")
  },
  {
    name: "setup-cleaning-infrastructure___en",
    path: "cleaning-infrastructure",
    component: () => import("/src/ckSetup/pages/setup/cleaning-infrastructure.vue")
  },
  {
    name: "setup-cleaning-other___en",
    path: "cleaning-other",
    component: () => import("/src/ckSetup/pages/setup/cleaning-other.vue")
  },
  {
    name: "setup-done___en",
    path: "done",
    meta: doneuznbBMWl12Meta || {},
    component: () => import("/src/ckSetup/pages/setup/done.vue")
  },
  {
    name: "setup-info___en",
    path: "info",
    component: () => import("/src/ckSetup/pages/setup/info.vue")
  },
  {
    name: "setup-intro___en",
    path: "intro",
    meta: intro531BtyXOF7Meta || {},
    component: () => import("/src/ckSetup/pages/setup/intro.vue")
  },
  {
    name: "setup-product-categories___en",
    path: "product-categories",
    component: () => import("/src/ckSetup/pages/setup/product-categories.vue")
  },
  {
    name: "setup-rooms___en",
    path: "rooms",
    component: () => import("/src/ckSetup/pages/setup/rooms.vue")
  },
  {
    name: "setup-setupSteps___en",
    path: "setupSteps",
    component: () => import("/src/ckSetup/pages/setup/setupSteps.ts")
  },
  {
    name: "setup-skills___en",
    path: "skills",
    component: () => import("/src/ckSetup/pages/setup/skills.vue")
  }
]
  },
  {
    name: "setup___es",
    path: "/es/setup",
    meta: setuphY0ccoWTcmMeta || {},
    component: () => import("/src/ckSetup/pages/setup.vue"),
    children: [
  {
    name: "setup-allergens___es",
    path: "allergens",
    component: () => import("/src/ckSetup/pages/setup/allergens.vue")
  },
  {
    name: "setup-categories___es",
    path: "categories",
    component: () => import("/src/ckSetup/pages/setup/categories.vue")
  },
  {
    name: "setup-cleaning-equipment___es",
    path: "cleaning-equipment",
    component: () => import("/src/ckSetup/pages/setup/cleaning-equipment.vue")
  },
  {
    name: "setup-cleaning-infrastructure___es",
    path: "cleaning-infrastructure",
    component: () => import("/src/ckSetup/pages/setup/cleaning-infrastructure.vue")
  },
  {
    name: "setup-cleaning-other___es",
    path: "cleaning-other",
    component: () => import("/src/ckSetup/pages/setup/cleaning-other.vue")
  },
  {
    name: "setup-done___es",
    path: "done",
    meta: doneuznbBMWl12Meta || {},
    component: () => import("/src/ckSetup/pages/setup/done.vue")
  },
  {
    name: "setup-info___es",
    path: "info",
    component: () => import("/src/ckSetup/pages/setup/info.vue")
  },
  {
    name: "setup-intro___es",
    path: "intro",
    meta: intro531BtyXOF7Meta || {},
    component: () => import("/src/ckSetup/pages/setup/intro.vue")
  },
  {
    name: "setup-product-categories___es",
    path: "product-categories",
    component: () => import("/src/ckSetup/pages/setup/product-categories.vue")
  },
  {
    name: "setup-rooms___es",
    path: "rooms",
    component: () => import("/src/ckSetup/pages/setup/rooms.vue")
  },
  {
    name: "setup-setupSteps___es",
    path: "setupSteps",
    component: () => import("/src/ckSetup/pages/setup/setupSteps.ts")
  },
  {
    name: "setup-skills___es",
    path: "skills",
    component: () => import("/src/ckSetup/pages/setup/skills.vue")
  }
]
  },
  {
    name: "setup___ar",
    path: "/ar/setup",
    meta: setuphY0ccoWTcmMeta || {},
    component: () => import("/src/ckSetup/pages/setup.vue"),
    children: [
  {
    name: "setup-allergens___ar",
    path: "allergens",
    component: () => import("/src/ckSetup/pages/setup/allergens.vue")
  },
  {
    name: "setup-categories___ar",
    path: "categories",
    component: () => import("/src/ckSetup/pages/setup/categories.vue")
  },
  {
    name: "setup-cleaning-equipment___ar",
    path: "cleaning-equipment",
    component: () => import("/src/ckSetup/pages/setup/cleaning-equipment.vue")
  },
  {
    name: "setup-cleaning-infrastructure___ar",
    path: "cleaning-infrastructure",
    component: () => import("/src/ckSetup/pages/setup/cleaning-infrastructure.vue")
  },
  {
    name: "setup-cleaning-other___ar",
    path: "cleaning-other",
    component: () => import("/src/ckSetup/pages/setup/cleaning-other.vue")
  },
  {
    name: "setup-done___ar",
    path: "done",
    meta: doneuznbBMWl12Meta || {},
    component: () => import("/src/ckSetup/pages/setup/done.vue")
  },
  {
    name: "setup-info___ar",
    path: "info",
    component: () => import("/src/ckSetup/pages/setup/info.vue")
  },
  {
    name: "setup-intro___ar",
    path: "intro",
    meta: intro531BtyXOF7Meta || {},
    component: () => import("/src/ckSetup/pages/setup/intro.vue")
  },
  {
    name: "setup-product-categories___ar",
    path: "product-categories",
    component: () => import("/src/ckSetup/pages/setup/product-categories.vue")
  },
  {
    name: "setup-rooms___ar",
    path: "rooms",
    component: () => import("/src/ckSetup/pages/setup/rooms.vue")
  },
  {
    name: "setup-setupSteps___ar",
    path: "setupSteps",
    component: () => import("/src/ckSetup/pages/setup/setupSteps.ts")
  },
  {
    name: "setup-skills___ar",
    path: "skills",
    component: () => import("/src/ckSetup/pages/setup/skills.vue")
  }
]
  },
  {
    name: "setup___ja",
    path: "/ja/setup",
    meta: setuphY0ccoWTcmMeta || {},
    component: () => import("/src/ckSetup/pages/setup.vue"),
    children: [
  {
    name: "setup-allergens___ja",
    path: "allergens",
    component: () => import("/src/ckSetup/pages/setup/allergens.vue")
  },
  {
    name: "setup-categories___ja",
    path: "categories",
    component: () => import("/src/ckSetup/pages/setup/categories.vue")
  },
  {
    name: "setup-cleaning-equipment___ja",
    path: "cleaning-equipment",
    component: () => import("/src/ckSetup/pages/setup/cleaning-equipment.vue")
  },
  {
    name: "setup-cleaning-infrastructure___ja",
    path: "cleaning-infrastructure",
    component: () => import("/src/ckSetup/pages/setup/cleaning-infrastructure.vue")
  },
  {
    name: "setup-cleaning-other___ja",
    path: "cleaning-other",
    component: () => import("/src/ckSetup/pages/setup/cleaning-other.vue")
  },
  {
    name: "setup-done___ja",
    path: "done",
    meta: doneuznbBMWl12Meta || {},
    component: () => import("/src/ckSetup/pages/setup/done.vue")
  },
  {
    name: "setup-info___ja",
    path: "info",
    component: () => import("/src/ckSetup/pages/setup/info.vue")
  },
  {
    name: "setup-intro___ja",
    path: "intro",
    meta: intro531BtyXOF7Meta || {},
    component: () => import("/src/ckSetup/pages/setup/intro.vue")
  },
  {
    name: "setup-product-categories___ja",
    path: "product-categories",
    component: () => import("/src/ckSetup/pages/setup/product-categories.vue")
  },
  {
    name: "setup-rooms___ja",
    path: "rooms",
    component: () => import("/src/ckSetup/pages/setup/rooms.vue")
  },
  {
    name: "setup-setupSteps___ja",
    path: "setupSteps",
    component: () => import("/src/ckSetup/pages/setup/setupSteps.ts")
  },
  {
    name: "setup-skills___ja",
    path: "skills",
    component: () => import("/src/ckSetup/pages/setup/skills.vue")
  }
]
  },
  {
    name: "setup___fr",
    path: "/fr/setup",
    meta: setuphY0ccoWTcmMeta || {},
    component: () => import("/src/ckSetup/pages/setup.vue"),
    children: [
  {
    name: "setup-allergens___fr",
    path: "allergens",
    component: () => import("/src/ckSetup/pages/setup/allergens.vue")
  },
  {
    name: "setup-categories___fr",
    path: "categories",
    component: () => import("/src/ckSetup/pages/setup/categories.vue")
  },
  {
    name: "setup-cleaning-equipment___fr",
    path: "cleaning-equipment",
    component: () => import("/src/ckSetup/pages/setup/cleaning-equipment.vue")
  },
  {
    name: "setup-cleaning-infrastructure___fr",
    path: "cleaning-infrastructure",
    component: () => import("/src/ckSetup/pages/setup/cleaning-infrastructure.vue")
  },
  {
    name: "setup-cleaning-other___fr",
    path: "cleaning-other",
    component: () => import("/src/ckSetup/pages/setup/cleaning-other.vue")
  },
  {
    name: "setup-done___fr",
    path: "done",
    meta: doneuznbBMWl12Meta || {},
    component: () => import("/src/ckSetup/pages/setup/done.vue")
  },
  {
    name: "setup-info___fr",
    path: "info",
    component: () => import("/src/ckSetup/pages/setup/info.vue")
  },
  {
    name: "setup-intro___fr",
    path: "intro",
    meta: intro531BtyXOF7Meta || {},
    component: () => import("/src/ckSetup/pages/setup/intro.vue")
  },
  {
    name: "setup-product-categories___fr",
    path: "product-categories",
    component: () => import("/src/ckSetup/pages/setup/product-categories.vue")
  },
  {
    name: "setup-rooms___fr",
    path: "rooms",
    component: () => import("/src/ckSetup/pages/setup/rooms.vue")
  },
  {
    name: "setup-setupSteps___fr",
    path: "setupSteps",
    component: () => import("/src/ckSetup/pages/setup/setupSteps.ts")
  },
  {
    name: "setup-skills___fr",
    path: "skills",
    component: () => import("/src/ckSetup/pages/setup/skills.vue")
  }
]
  },
  {
    name: "setup___hi",
    path: "/hi/setup",
    meta: setuphY0ccoWTcmMeta || {},
    component: () => import("/src/ckSetup/pages/setup.vue"),
    children: [
  {
    name: "setup-allergens___hi",
    path: "allergens",
    component: () => import("/src/ckSetup/pages/setup/allergens.vue")
  },
  {
    name: "setup-categories___hi",
    path: "categories",
    component: () => import("/src/ckSetup/pages/setup/categories.vue")
  },
  {
    name: "setup-cleaning-equipment___hi",
    path: "cleaning-equipment",
    component: () => import("/src/ckSetup/pages/setup/cleaning-equipment.vue")
  },
  {
    name: "setup-cleaning-infrastructure___hi",
    path: "cleaning-infrastructure",
    component: () => import("/src/ckSetup/pages/setup/cleaning-infrastructure.vue")
  },
  {
    name: "setup-cleaning-other___hi",
    path: "cleaning-other",
    component: () => import("/src/ckSetup/pages/setup/cleaning-other.vue")
  },
  {
    name: "setup-done___hi",
    path: "done",
    meta: doneuznbBMWl12Meta || {},
    component: () => import("/src/ckSetup/pages/setup/done.vue")
  },
  {
    name: "setup-info___hi",
    path: "info",
    component: () => import("/src/ckSetup/pages/setup/info.vue")
  },
  {
    name: "setup-intro___hi",
    path: "intro",
    meta: intro531BtyXOF7Meta || {},
    component: () => import("/src/ckSetup/pages/setup/intro.vue")
  },
  {
    name: "setup-product-categories___hi",
    path: "product-categories",
    component: () => import("/src/ckSetup/pages/setup/product-categories.vue")
  },
  {
    name: "setup-rooms___hi",
    path: "rooms",
    component: () => import("/src/ckSetup/pages/setup/rooms.vue")
  },
  {
    name: "setup-setupSteps___hi",
    path: "setupSteps",
    component: () => import("/src/ckSetup/pages/setup/setupSteps.ts")
  },
  {
    name: "setup-skills___hi",
    path: "skills",
    component: () => import("/src/ckSetup/pages/setup/skills.vue")
  }
]
  },
  {
    name: "setup___pt",
    path: "/pt/setup",
    meta: setuphY0ccoWTcmMeta || {},
    component: () => import("/src/ckSetup/pages/setup.vue"),
    children: [
  {
    name: "setup-allergens___pt",
    path: "allergens",
    component: () => import("/src/ckSetup/pages/setup/allergens.vue")
  },
  {
    name: "setup-categories___pt",
    path: "categories",
    component: () => import("/src/ckSetup/pages/setup/categories.vue")
  },
  {
    name: "setup-cleaning-equipment___pt",
    path: "cleaning-equipment",
    component: () => import("/src/ckSetup/pages/setup/cleaning-equipment.vue")
  },
  {
    name: "setup-cleaning-infrastructure___pt",
    path: "cleaning-infrastructure",
    component: () => import("/src/ckSetup/pages/setup/cleaning-infrastructure.vue")
  },
  {
    name: "setup-cleaning-other___pt",
    path: "cleaning-other",
    component: () => import("/src/ckSetup/pages/setup/cleaning-other.vue")
  },
  {
    name: "setup-done___pt",
    path: "done",
    meta: doneuznbBMWl12Meta || {},
    component: () => import("/src/ckSetup/pages/setup/done.vue")
  },
  {
    name: "setup-info___pt",
    path: "info",
    component: () => import("/src/ckSetup/pages/setup/info.vue")
  },
  {
    name: "setup-intro___pt",
    path: "intro",
    meta: intro531BtyXOF7Meta || {},
    component: () => import("/src/ckSetup/pages/setup/intro.vue")
  },
  {
    name: "setup-product-categories___pt",
    path: "product-categories",
    component: () => import("/src/ckSetup/pages/setup/product-categories.vue")
  },
  {
    name: "setup-rooms___pt",
    path: "rooms",
    component: () => import("/src/ckSetup/pages/setup/rooms.vue")
  },
  {
    name: "setup-setupSteps___pt",
    path: "setupSteps",
    component: () => import("/src/ckSetup/pages/setup/setupSteps.ts")
  },
  {
    name: "setup-skills___pt",
    path: "skills",
    component: () => import("/src/ckSetup/pages/setup/skills.vue")
  }
]
  }
]