/**
 * PlantApi
 * 1.0
 * DO NOT MODIFY - This file has been generated using oazapfts.
 * See https://www.npmjs.com/package/oazapfts
 */
import * as Oazapfts from "oazapfts/lib/runtime";
import * as QS from "oazapfts/lib/runtime/query";
export const defaults: Oazapfts.Defaults<Oazapfts.CustomHeaders> = {
    headers: {},
    baseUrl: "/",
};
const oazapfts = Oazapfts.runtime(defaults);
export const servers = {};
export type CleaningRoleDto = {
    id: number;
    category: string;
    name: string;
};
export type DataStatus = "success" | "notValid" | "exception" | "error" | "inUse" | "notFound" | "noChange" | "inProgress" | "invalidPage" | "duplicateEntity" | "noContent";
export type ErrorResponse = {
    message: string;
    kind: DataStatus;
    exception: string | null;
    stackTrace: string | null;
    exceptionType: string;
    innerException: string | null;
    validationErrors: {
        [key: string]: string[];
    } | null;
};
export function getCleaningRoles(category: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: CleaningRoleDto[];
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>(`/api/setup/cleaningroles/${encodeURIComponent(category)}`, {
        ...opts
    });
}
