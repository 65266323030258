/**
 * PlantApi
 * 1.0
 * DO NOT MODIFY - This file has been generated using oazapfts.
 * See https://www.npmjs.com/package/oazapfts
 */
import * as Oazapfts from "oazapfts/lib/runtime";
import * as QS from "oazapfts/lib/runtime/query";
export const defaults: Oazapfts.Defaults<Oazapfts.CustomHeaders> = {
    headers: {},
    baseUrl: "/",
};
const oazapfts = Oazapfts.runtime(defaults);
export const servers = {};
export type FacilitySetupStatus = "unknown" | "new" | "dataImportInProgress" | "setupComplete";
export type ProductCategoryDto = {
    description: string;
};
export type InfrastructureCleaningMethod = "invalid" | "dailyWet" | "downDayWet" | "downDayDry";
export type CleaningRoleDto = {
    id: number;
    category: string;
    name: string;
};
export type FacilitySkillDto = {
    id: number;
    name: string | null;
};
export type CorpCatDto = {
    "number": number;
    name: string;
    active: boolean;
};
export type FacilityDto = {
    id: number;
    name: string | null;
    organizationId?: number | null;
    setupStatus: FacilitySetupStatus;
    minimumRoomsAreas: number;
    maximumRoomsAreas: number;
    minimumBuildings: number;
    maximumBuildings: number;
    productCategories: ProductCategoryDto[];
    infrastructureCleaningMethod: InfrastructureCleaningMethod;
    productionDryCleaning: boolean;
    productionWetCleaning: boolean;
    useAllergens: boolean;
    allergensDifferentPerProduct: boolean;
    active: boolean;
    infrastructureCleaningRoles: CleaningRoleDto[];
    equipmentCleaningRoles: CleaningRoleDto[];
    routineCleaningRoles: CleaningRoleDto[];
    janitorialCleaningRoles: CleaningRoleDto[];
    changeoverCleaningRoles: CleaningRoleDto[];
    skills: FacilitySkillDto[];
    corporateCategories: CorpCatDto[];
};
export type FacilityDtoListResultSet = {
    totalResults: number;
    totalPages: number;
    currentPage: number;
    pageSize: number;
    previousUrl: string | null;
    nextUrl: string | null;
    filter: any | null;
    data: FacilityDto[];
};
export type DataStatus = "success" | "notValid" | "exception" | "error" | "inUse" | "notFound" | "noChange" | "inProgress" | "invalidPage" | "duplicateEntity" | "noContent";
export type ErrorResponse = {
    message: string;
    kind: DataStatus;
    exception: string | null;
    stackTrace: string | null;
    exceptionType: string;
    innerException: string | null;
    validationErrors: {
        [key: string]: string[];
    } | null;
};
export type FacilityDtoResultSet = {
    totalResults: number;
    totalPages: number;
    currentPage: number;
    pageSize: number;
    previousUrl: string | null;
    nextUrl: string | null;
    filter: any | null;
    data: FacilityDto;
};
export type ValidationProblemDetails = {
    "type": string | null;
    title: string | null;
    status?: number | null;
    detail: string | null;
    instance: string | null;
    errors: {
        [key: string]: string[];
    };
    [key: string]: any;
};
export type IndustryLookupView = {
    id: number;
    name: string;
};
export function getFacilityList({ page, pageSize }: {
    page?: number;
    pageSize?: number;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: FacilityDtoListResultSet;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>(`/api/setup/facilities${QS.query(QS.explode({
        page,
        pageSize
    }))}`, {
        ...opts
    });
}
export function createFacility(facilityDto: FacilityDto, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: FacilityDtoResultSet;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
        data: ErrorResponse;
    } | {
        status: 422;
        data: ValidationProblemDetails;
    } | {
        status: 500;
        data: ErrorResponse;
    }>("/api/setup/facilities", oazapfts.json({
        ...opts,
        method: "POST",
        body: facilityDto
    }));
}
export function getFacility(id: number, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: FacilityDtoResultSet;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>(`/api/setup/facilities/${encodeURIComponent(id)}`, {
        ...opts
    });
}
export function updateFacility(id: number, facilityDto: FacilityDto, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: FacilityDtoResultSet;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
        data: ErrorResponse;
    } | {
        status: 422;
        data: ValidationProblemDetails;
    } | {
        status: 500;
        data: ErrorResponse;
    }>(`/api/setup/facilities/${encodeURIComponent(id)}`, oazapfts.json({
        ...opts,
        method: "PUT",
        body: facilityDto
    }));
}
export function deleteFacility(id: number, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: FacilityDtoResultSet;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>(`/api/setup/facilities/${encodeURIComponent(id)}`, {
        ...opts,
        method: "DELETE"
    });
}
/**
 * Number and Name for Industries for use in most Drop Downs
 */
export function getIndustryLookupList(opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: IndustryLookupView[];
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>("/api/industrylookuplist", {
        ...opts
    });
}
