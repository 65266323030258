import { z } from "zod";
import { makeZodI18nMap } from "~/schemas/zod-vue-i18n";
import type { I18n } from "vue-i18n";

export default defineNuxtPlugin({
  dependsOn: ["settings"],
  setup(nuxtApp) {
    z.setErrorMap(makeZodI18nMap(<I18n>{ global: nuxtApp.$i18n }, "zodErrors"));
  },
});
