<script setup lang="ts">
import { getUID } from "~/utils/uniqueID";

const props = defineProps({
  value: {
    type: Number,
    default: 0,
  },
  labelClasses: {
    type: String,
    default: undefined,
  },
  progressClasses: {
    type: String,
    default: "progress-bar-default border-2 border-ngray-3 w-[100px] mx-10",
  },
  progressContainerClasses: {
    type: String,
    default: "flex flex-col items-center pb-2 ",
  },
});
const percentage = computed(() => props.value + "%");
const id = getUID("progress");
</script>

<template>
  <div :class="progressContainerClasses">
    <div class="flex justify-between w-full pb-1">
      <slot v-if="$slots.labelText" name="labelText" />
      <label :for="id" :class="labelClasses">{{ percentage }}</label>
    </div>

    <progress :id="id" max="100" :value="value" :class="progressClasses">{{ percentage }}</progress>
  </div>
</template>

<style scoped lang="postcss">
.progress-bar-default {
  --color: var(--color-nblue-2);
  --background: var(--color-nblue-6);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 10em;
  background: var(--background);
}
.progress-bar-default::-webkit-progress-bar {
  border-radius: 10em;
  background: var(--background);
}
.progress-bar-default::-webkit-progress-value {
  border-radius: 10em;
  background: var(--color);
}
</style>
