import type { WizardStep } from "@base/types/wizardSteps";

export default new Array<WizardStep>(
  {
    url: "/setup/intro",
    hideSidebar: true,
  },
  {
    title: "cksetup.wizard.facilityInfo",
    url: "/setup/info",
  },
  {
    title: "cksetup.wizard.productCategories",
    url: "/setup/product-categories",
  },
  {
    title: "cksetup.wizard.rooms",
    url: "/setup/rooms",
  },
  {
    title: "cksetup.wizard.cleaningInfrastructure",
    url: "/setup/cleaning-infrastructure",
  },
  {
    title: "cksetup.wizard.cleaningEquipment",
    url: "/setup/cleaning-equipment",
  },
  {
    title: "cksetup.wizard.cleaningOther",
    url: "/setup/cleaning-other",
  },
  {
    title: "cksetup.wizard.allergens",
    url: "/setup/allergens",
  },
  {
    title: "cksetup.wizard.skills",
    url: "/setup/skills",
  },
  {
    title: "cksetup.wizard.corporateCategories",
    url: "/setup/categories",
  },
  {
    url: "/setup/done",
    hideSidebar: true,
  },
);
