/**
 * PlantApi
 * 1.0
 * DO NOT MODIFY - This file has been generated using oazapfts.
 * See https://www.npmjs.com/package/oazapfts
 */
import * as Oazapfts from "oazapfts/lib/runtime";
import * as QS from "oazapfts/lib/runtime/query";
export const defaults: Oazapfts.Defaults<Oazapfts.CustomHeaders> = {
    headers: {},
    baseUrl: "/",
};
const oazapfts = Oazapfts.runtime(defaults);
export const servers = {};
export type OrganizationDto = {
    id: number;
    name: string | null;
    industry: string | null;
};
export type DataStatus = "success" | "notValid" | "exception" | "error" | "inUse" | "notFound" | "noChange" | "inProgress" | "invalidPage" | "duplicateEntity" | "noContent";
export type ErrorResponse = {
    message: string;
    kind: DataStatus;
    exception: string | null;
    stackTrace: string | null;
    exceptionType: string;
    innerException: string | null;
    validationErrors: {
        [key: string]: string[];
    } | null;
};
export type ValidationProblemDetails = {
    "type": string | null;
    title: string | null;
    status?: number | null;
    detail: string | null;
    instance: string | null;
    errors: {
        [key: string]: string[];
    };
    [key: string]: any;
};
export function getOrganizations(opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: OrganizationDto[];
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>("/api/setup/organizations", {
        ...opts
    });
}
export function createOrganization(organizationDto: OrganizationDto, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: OrganizationDto;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
        data: ErrorResponse;
    } | {
        status: 422;
        data: ValidationProblemDetails;
    } | {
        status: 500;
        data: ErrorResponse;
    }>("/api/setup/organizations", oazapfts.json({
        ...opts,
        method: "POST",
        body: organizationDto
    }));
}
export function getOrganization(id: number, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: OrganizationDto;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>(`/api/setup/organizations/${encodeURIComponent(id)}`, {
        ...opts
    });
}
export function updateOrganization(id: number, organizationDto: OrganizationDto, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: OrganizationDto;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
        data: ErrorResponse;
    } | {
        status: 422;
        data: ValidationProblemDetails;
    } | {
        status: 500;
        data: ErrorResponse;
    }>(`/api/setup/organizations/${encodeURIComponent(id)}`, oazapfts.json({
        ...opts,
        method: "PUT",
        body: organizationDto
    }));
}
export function deleteOrganization(id: number, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: OrganizationDto;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>(`/api/setup/organizations/${encodeURIComponent(id)}`, {
        ...opts,
        method: "DELETE"
    });
}
export function getCurrentOrganization(opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: OrganizationDto;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>("/api/setup/organizations/current", {
        ...opts
    });
}
